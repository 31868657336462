var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Demo-DOH-PQR-CHECK mb-4"},[_c('div',{staticClass:"col-lg-6 col-md-12 container-md justify-content-center mt-3 border-doh px-0"},[(_vm.isFetchingFilter)?_c('LoadingOverlay'):_vm._e(),(!_vm.isPQRChecked)?_c('div',[_c('h4',{staticClass:"bg-doh p-2"},[_vm._v("Please Enter Your Highest Qualification")]),_c('div',{staticClass:"px-3"},[_c('ValidationObserver',{ref:"pqrCheck",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"name":"pqrCheck"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Profession")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"candidate_type_id","value":_vm.pqr.candidate_type_id,"options":_vm.options && _vm.options['candidateType']
                        ? _vm.options['candidateType']
                        : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)]),_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Highest Qualification Obtained In")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"main_qualification_country_id","value":_vm.pqr.main_qualification_country_id,"options":_vm.options && _vm.options['locationInfo']
                        ? _vm.options['locationInfo']
                        : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)]),_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Qualification Name")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"qualification_id","value":_vm.pqr.qualification_id,"options":_vm.options && _vm.options['qualification']
                        ? _vm.options['qualification']
                        : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)]),_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Month / Year Awarded")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateInput',{attrs:{"onlyMonthAndYear":true,"name":"qualified_date","value":_vm.pqr.qualified_date,"error":errors[0]},on:{"change":_vm.handleDatePickerChange}})]}}],null,true)})],1)]),_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Issued By")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"issued_by","value":_vm.pqr.issued_by,"options":_vm.options && _vm.options['issued_by']
                        ? _vm.options['issued_by']
                        : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)]),_c('CRow',[(
                  _vm.pqr.qualification_id &&
                  _vm.pqr.qualification_id.specialist_registration_reqd
                )?_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Are you on a Specialist Register?")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{ref:"specialist_registration",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('RadioButton',{attrs:{"name":"specialist_registration","value":_vm.pqr.specialist_registration,"options":_vm.options && _vm.options['specialistRegister']
                            ? _vm.options['specialistRegister']
                            : [],"error":errors[0]},on:{"change":_vm.handleChangeRadio}})]}}],null,true)})],1)])],1):_vm._e(),(_vm.payload.specialist_registration)?_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Acquired Date")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateInput',{attrs:{"onlyMonthAndYear":true,"name":"acquired_date","value":_vm.pqr.acquired_date,"error":errors[0]},on:{"input":_vm.handleDatePickerChange}})]}}],null,true)})],1)])],1):_vm._e()],1),_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12 required",attrs:{"name":"lbl_course_duration"}},[_vm._v(" Course Duration (yrs) "),_c('strong',[_vm._v(" Selected - "+_vm._s(_vm.pqr.course_duration ? _vm.pqr.course_duration : "0")+" ")])]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('v-slider',{attrs:{"name":"course_duration","min":0,"max":10,"value":_vm.pqr.course_duration},on:{"change":_vm.handleSlider}})],1)]),_c('CRow',{staticClass:"row mb-3",attrs:{"name":"speciality"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Speciality")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"speciality_id","value":_vm.pqr.speciality_id,"options":_vm.options && _vm.options['speciality']
                        ? _vm.options['speciality']
                        : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)]),(_vm.options['subSpeciality'].length)?_c('CRow',{staticClass:"row mb-3",attrs:{"name":"sub_speciality"}},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v(_vm._s(_vm.isNurse ? `Area of Expertise` : `Sub Speciality`))]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('Select',{attrs:{"name":"sub_speciality_id","value":_vm.pqr.sub_speciality_id,"options":_vm.options && _vm.options['subSpeciality']
                      ? _vm.options['subSpeciality']
                      : [],"taggable":false,"multiple":false,"clearable":true},on:{"input":_vm.handleChangeSelect}})],1)]):_vm._e(),_c('div',{staticClass:"mb-2 d-flex justify-content-end"},[_c('CButton',{staticClass:"d-flex m-2 px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v(" Check ")])],1)],1)]}}],null,false,1015427408)})],1)]):_c('div',{staticClass:"p-3"},[_c('CCard',[_c('CCardBody',[_c('h5',[_vm._v("Eligibility Matched")]),_c('CRow',{staticClass:"mb-2"},[_c('CCol',{staticClass:"pr-0",attrs:{"col":"5"}},[_vm._v("Eligibility Country")]),_c('CCol',{staticClass:"pr-0",attrs:{"col":"7"}},[_vm._v(_vm._s(_vm.getStandAlonePQR.pqr_country || "--"))])],1),_c('CRow',{staticClass:"mb-2"},[_c('CCol',{staticClass:"pr-0",attrs:{"col":"5"}},[_vm._v("Authority")]),_c('CCol',{staticClass:"pr-0",attrs:{"col":"7"}},[_vm._v(_vm._s(_vm.getStandAlonePQR.pqr_issue_authority || "--"))])],1),_c('CRow',{staticClass:"mb-2"},[_c('CCol',{staticClass:"pr-0",attrs:{"col":"5"}},[_vm._v("Eligibility Level")]),_c('CCol',{staticClass:"pr-0",attrs:{"col":"7"}},[_vm._v(_vm._s(_vm.getStandAlonePQR.pqr_detail_description || "--"))])],1),_c('CRow',{staticClass:"mb-2"},[_c('CCol',{staticClass:"pr-0",attrs:{"col":"5"}},[_vm._v("Comments")]),_c('CCol',{staticClass:"pr-0",attrs:{"col":"7"}},[_vm._v(_vm._s(_vm.getStandAlonePQR.pqr_detail_comments || "--"))])],1),_c('CRow',{staticClass:"mb-2"},[_c('CCol',{staticClass:"pr-0",attrs:{"col":"5"}},[_vm._v("Date Created")]),_c('CCol',{staticClass:"pr-0",attrs:{"col":"7"}},[_vm._v(_vm._s(_vm.dateFormat(_vm.getStandAlonePQR.created_date)))])],1)],1)],1),_c('div',{staticClass:"mb-2 px-5"},[_c('CButton',{staticClass:"w-100",attrs:{"color":"primary"},on:{"click":_vm.resetPQR}},[_vm._v(" Check Another Qualification ")])],1),_c('div',{staticClass:"px-5"},[_c('CButton',{staticClass:"w-100",attrs:{"color":"primary"},on:{"click":_vm.navToDOHJobBoard}},[_vm._v(" Register Now ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }