import { render, staticRenderFns } from "./PQRCheck.vue?vue&type=template&id=e514088c&scoped=true"
import script from "./PQRCheck.vue?vue&type=script&lang=js"
export * from "./PQRCheck.vue?vue&type=script&lang=js"
import style0 from "./PQRCheck.vue?vue&type=style&index=0&id=e514088c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e514088c",
  null
  
)

export default component.exports