<template>
  <div class="Demo-DOH">
    <header
      class="bg-clr sticky-top flex-d c-header c-header-light c-header-fixed c-header-with-subheader"
    >
      <div>
        <div class="col-12 logo">
          <div>
            <a
              href="https://www.doh.gov.ae/covid-19/Health-Workforce-Management-System"
              target="_blank"
              class="p-1 d-inline-block"
              ><img src="/img/white-logo-doh.svg" class="mw-100 h-auto"
            /></a>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column logo-title p-2 linespcad title">
        <div>
          <span class="fs-12">Abu</span>
          <span class="fs-12"> Dhabi's </span>
          <span class="fs-12"> Health </span>
          <span class="fs-12"> Workforce </span>
          <span class="fs-12">
            Careers
          </span>
          <span class="fs-12">
            Portal
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center right-action">
        <a href="/demo/doh/8" class="c-header-nav-link text-white hover-decoration-none fs-12">Job Board</a>
      </div>
    </header>
    <div style="margin: 70px 0px; padding-top: 2rem" class="container-fluid">
      <PQRCheck />
    </div>
    <footer
      class="page-footer f-bg font-small white text-white c-footer c-footer-fixed"
    >
      <div>
        <div class="ml-1 copy-right">
          © 2011 – 2020 OHR Ltd or its affiliates
        </div>
      </div>
      <div class="ml-auto d-flex flex-column">
        <span class="mr-1"
          ><span class="fw-b"
            ><div class="logo-adjustment">powered by</div>
            TALENT</span
          ><span class="fw-5">FIND™</span></span
        >
      </div>
    </footer>
  </div>
</template>

<script>
import PQRCheck from "@/pages/DemoDOH/PQRCheck";
export default {
  name: "DemoDOHPQRCheck",
  components: {
    PQRCheck,
  },
};
</script>

<style lang="scss" scoped>
.Demo-DOH {
  .c-header.c-header-fixed {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    font-family: "Roboto",sans-serif !important;
  }
  .c-header[class*="bg-"] {
    border-color: rgba(0, 0, 21, 0.1);
  }
  .bg-clr {
    background-color: rgb(10, 94, 103);
  }
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
  .hover-decoration-none:hover{
    text-decoration: none;
  }
}
.fs-12{
  font-size: 12.8px;
}
</style>
